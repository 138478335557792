<template>
    <div class="container mt-4">
        <div class="row">
            <MovieCard v-for="movie in movies" :key="movie.id" :movie="movie" />
        </div>
    </div>
</template>

<script>
    import MovieCard from './MovieCard.vue';
    import axios from 'axios';

    export default{
        components:{MovieCard},
        data(){
            return{
                movies:[]
            };
        },
        async created(){
            const response=await axios.get('https://api.themoviedb.org/3/movie/upcoming?api_key=b8fe22e9bf4adce6153cbe7c0c83098a');
        this.movies=response.data.results;
        }
    };
</script>