import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../components/HomeView.vue';
import MovieDetail from '../components/MovieDetail.vue';
import TrendingView from '../components/TrendingView.vue';
import PopularView from '../components/PopularView.vue';
import TopRatedView from '../components/TopRatedView.vue';
import NowPlayingView from '../components/NowPlayingView.vue';
import UpcomingView from '../components/UpcomingView.vue';

const routes=[
    {path:'/', name:'home', component:HomeView},
    {path:'/movie/:id', name:'movie-detail', component:MovieDetail},
    {path:'/trending', name:'trending', component:TrendingView},
    {path:'/popular', name:'popular', component:PopularView},
    {path:'/top-rated', name:'top-rated', component:TopRatedView},
    {path:'/now-playing', name:'now-playing', component:NowPlayingView},
    {path:'/upcoming', name:'upcoming', component:UpcomingView},
];

const router=createRouter({
    history:
    createWebHistory(process.env.BASE_URL), routes
});

export default router;