<template>
    <div class="container mt-4" v-if="movie">
        <div class="row">
            <div class="col md-4">
                <img :src="imageUrl" class="img-fluid" :alt="movie.title">
            </div>
            <div class="col md-8">
                <h2>{{ movie.title }}</h2>
                <p>{{ movie.overview }}</p>
                <p><strong>Release Date:</strong>{{ movie.release_date }}</p>
                <p><strong>Rating:</strong>{{ movie.vote_average }}</p>
                <router-link to="/" class="btn btn-secondary">Back to Home</router-link>
            </div>
        </div>
    

    <!--Video Section-->
    <div class="row mt-5" v-if="videos.length">
        <div class="col">
            <h4>Watch Trailer</h4>
            <div v-for="video in videos" :key="video.id" class="mb-3">
                <iframe v-if="video.site==='YouTube'" :src="'https://www.youtube.com/embed/'+video.key"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write;encrypted-media;gyroscope;picture-in-picture"
                allowfullscreen
                class="w-100"
                style="height: 315px;"
                ></iframe>
            </div>
        </div>
    </div>
</div>
</template>


<script>
    import axios from 'axios';

    export default{
        data(){
            return{
                movie:null,
                videos:[],
            };
        },
       async created(){
            try{
        const response=await axios.get('https://api.themoviedb.org/3/movie/' + this.$route.params.id +'?api_key=b8fe22e9bf4adce6153cbe7c0c83098a');
        this.movie=response.data;
        //Fetching video data
        const videoResponse=await axios.get('https://api.themoviedb.org/3/movie/' + this.$route.params.id + '/videos?api_key=b8fe22e9bf4adce6153cbe7c0c83098a');
        if(videoResponse.data && Array.isArray(videoResponse.data.results)){
        this.videos=videoResponse.data.results.filter(video=>video.type==='Trailer');
            }else {
                console.warn('No videos found or unexpected response structure');
            }
       } catch(error){
        console.error("Error fetching movie details or videos:", error);
       }
       },
        computed:{
            imageUrl(){
               
                return this.movie && this.movie.poster_path
                        ?
            'https://image.tmdb.org/t/p/w500/' + this.movie.poster_path
                        :
            'https://via.placeholder.com/500x750?text=No+Image+Available';
              
            }
        }
   
    };
</script>