<template>
    <div class="col-md-3 mb-4">
        <div class="card h-100">
            <img v-if="imageUrl" :src="imageUrl" class="card-img-top" alt="Movie Poster">
            <div class="card-body">
                <h5 class="card-title">{{ movie.title }}</h5> 
                <p class="card-text">Rating:{{ movie.vote_average }}</p>
                <router-link :to="{name:'movie-detail', params:{id:movie.id}}" class="btn btn-primary">View Details</router-link>
            </div>
        </div>
    </div>
</template>


<script>
export default{
    props:['movie'],
    computed:{
        imageUrl(){
            console.log(this.movie.poster_path);
            return this.movie.poster_path
                    ?
            'https://image.tmdb.org/t/p/w500/' + this.movie.poster_path :'https://via.placeholder.com/500x750?text=No+Image+Available';
            
        }
    }
};
</script>

<style>
    .card-img-top{
        display:block;
    }
</style>