<template>
    <div class="container mt-4">
        <div class="row">
            <MovieCard v-for="movie in movies" :key="movie.id" :movie="movie" />
        </div>
    </div>
</template>

<script>
    import MovieCard from './MovieCard.vue';
    import axios from 'axios';

    export default{
        components:{MovieCard},
        data(){
            return{
                movies:[]
            };
        },
        watch:{
            '$route.query.search':{
                handler(newValue){
                    this.fetchMovies(newValue);
                },
                immediate:true
            }
        },
        methods:{
            async fetchMovies(query){
                let url='https://api.themoviedb.org/3/movie/popular?api_key=b8fe22e9bf4adce6153cbe7c0c83098a';
                if(query){
                    url='https://api.themoviedb.org/3/search/movie?api_key=b8fe22e9bf4adce6153cbe7c0c83098a&query=${query}';
                }
                const response=await axios.get(url);
                this.movies=response.data.results;
            }
        },
    };
</script>