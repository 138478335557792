<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <div class="container">
        <a class="navbar-brand" href="#">
          <i class="fas fa-film"></i>Vueflix
        </a>
        <div class="collapse navbar-collapse">
          <ul class="navbar-nav mx-auto">
            <li class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/trending" class="nav-link">Trending</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/popular" class="nav-link">Popular</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/top-rated" class="nav-link">Top Rated</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/now-playing" class="nav-link">Now Playing</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/upcoming" class="nav-link">Upcoming</router-link>
            </li>
          </ul>
        </div>
        <form class="form-inline" @submit.prevent="searchMovie">
          <input v-model="query" class="form-control mr-sm-2" type="search" placeholder="Search Movies" aria-label="Search">
          <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
        </form>
      </div>
    </nav>
    <router-view @searched="setSearchResults"></router-view>
  </div>

  <footer class="bg-dark text-white text-center py-4">
    <div class="container">
      <p>&copy; 2024 Vueflix. All rights reserved.</p>
    </div>
  </footer>
</template>

<script>


export default {
  data(){
    return{
      query:'',
    };
  },
  methods:{
    searchMovie(){
      this.$router.push({name:'home', query:{search:this.query}});
    }
  }
  };

</script>

<style>
@import 'https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css';
body{
  background-color: #f8f9fa;
  font-family: 'Arial', sans-serif;
}

header nav{
  margin-bottom: 20px;
}


footer{
  margin-top: 20px;
}

.navbar-nav .nav-link{
  color:#fff;
  font-size: 18px;
  padding: 10px 15px;
}

.navbar-nav .nav-link.active{
  background-color: rgba(255,255,255,0.1);
  border-radius: 5px;
}
</style>
